// extracted by mini-css-extract-plugin
export var desc = "educationPage-module--desc--eJJlM";
export var descProject = "educationPage-module--descProject--1oLLs";
export var descProjectLast = "educationPage-module--descProjectLast--1nGCn";
export var header = "educationPage-module--header--OmfmJ";
export var divPro = "educationPage-module--divPro--33ljG";
export var eduMain = "educationPage-module--eduMain--3PI6L";
export var link = "educationPage-module--link--1yOgm";
export var linkIcon = "educationPage-module--linkIcon---DKKN";
export var container = "educationPage-module--container--3Mv2e";
export var rightTxt = "educationPage-module--rightTxt--1LJw_";
export var line = "educationPage-module--line--OvFT1";
export var paraContent = "educationPage-module--paraContent--3lGu5";
export var paraName = "educationPage-module--paraName--3qHqL";